import React from 'react'
import RecentViewedProductSlider from '../RecentViewedProductSlider/RecentViewedProductSlider'
import ProductTileSet from '../ProductTiles/partials/ProductTileSet'
import { Loader } from '@saatva-bits/pattern-library.components.loader'

import styles from './RecentlyViewedSection.module.scss'

const RecentlyViewedSection = ({ productsData, isLoading }) => {
    return (
        <section className='section  u-bgColor--contrast2' id='recently-viewed-section'>
            <div className='container'>
                <h2 className='u-marginBottom--2xl t-heading2 t-color'>Recently Viewed</h2>
                {isLoading
                    ? <Loader className={styles.loader} loaderItemCount={4} size="md" isInline={true} />
                    : <>
                        <ProductTileSet
                            containerClassname='u-hidden--md-down'
                            products={productsData}
                            productTileClassname={styles.productTileContainer}
                            containerSelector="recently-viewed-product-tiles"
                            noSort
                        />
                        <RecentViewedProductSlider
                            title={null}
                            sectionClassname={styles.sliderSection}
                            recentlyViewedData={{ products: productsData }}
                            dataSelector="recently-viewed-slider"
                        />
                    </>
                }
            </div>
        </section>
    )
}

export default RecentlyViewedSection