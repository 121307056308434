import PropTypes from 'prop-types'
import classNames from 'classnames'

import { LaunchDarklyWrapper } from '@saatva-bits/pattern-library.modules.launch-darkly'

import ProductTileSet from './partials/ProductTileSet'
import ShoppableTileSet from './partials/ShoppableTileSet'

import CATEGORIES from '@/temp-configs/category-constants'

import styles from './ProductTiles.module.scss'

const ProductTiles = ({ products, sectionClassname }) => {
    const sectionClassnames = classNames('section', sectionClassname)
    return (
        <section className={sectionClassnames}>
            <div className={`container ${styles.shoppableTile}`}>
                <h2 className="u-marginBottom--2xl t-heading2">
                    Find the right Saatva mattress for&nbsp;you
                </h2>
                <LaunchDarklyWrapper flagKey="EXP.HP_MATTRESS_TILES.DIS-271" versionVariant="V0">
                    <ProductTileSet products={products} />
                </LaunchDarklyWrapper>
                <LaunchDarklyWrapper flagKey="EXP.HP_MATTRESS_TILES.DIS-271" versionVariant="V1">
                    <ShoppableTileSet products={products} />
                </LaunchDarklyWrapper>
                <div className="u-marginTop--xl">
                    <a className={`t-link ${styles.viewMoreLink}`} href={`/${CATEGORIES.MATTRESSES}`} target="_self">
                        View More
                    </a>
                </div>
            </div>
        </section>
    )
}

ProductTiles.propTypes = {
    products: PropTypes.array.isRequired
}

export default ProductTiles
