import { useMemo } from 'react'
import { SelectionProvider } from '@saatva-bits/pattern-library.modules.selection'
import { getRecentlyViewed } from '@/utils/recentlyViewed'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly'

import Layout from '@/layouts/Default'
import HeroSection from '@/components/HeroSection/HeroSection'
import ValuePropsSection from '@/components/ValuePropsSection/ValuePropsSection'
import ProductTiles from '@/components/ProductTiles/ProductTiles'
import VideoSection from '@/components/VideoSection/VideoSection'
import CategoryTiles from '@/components/CategoryTiles/CategoryTiles'
import WhySaatva from '@/components/WhySaatva/WhySaatva'
import ReviewSection from '@/components/ReviewSection/ReviewSection'
import RecentlyViewedSection from '@/components/RecentlyViewedSection/RecentlyViewedSection'
import useFetchRecentlyViewedData from '@/hooks/useFetchRecentlyViewedData'

export default function HomeView(props) {
    const products = props.products
    const { isV1: isViewedProdRecStripEnable } = useExperiment('EXP.HP_RECENTLY_VIEWED.DIS-468')

    const viewedProducts = useMemo(() => {
        if (isViewedProdRecStripEnable) {
            return getRecentlyViewed().slice(0, 3)
        }
        return []
    }, [isViewedProdRecStripEnable])
    
    const { recentlyViewedData, loading } = useFetchRecentlyViewedData(viewedProducts)

    const productsData = [...products, ...recentlyViewedData]

    return (
        <Layout {...props}>
            <SelectionProvider productsData={productsData} defaultInStock={true}>
                <HeroSection {...props} />
                <ValuePropsSection />
                {isViewedProdRecStripEnable && viewedProducts.length > 0 &&
                    <RecentlyViewedSection
                        productsData={recentlyViewedData}
                        isLoading={loading}
                    />
                }
                <ProductTiles 
                    products={products} 
                    sectionClassname={ isViewedProdRecStripEnable ? 'u-bgColor--contrastWhite' : 'u-bgColor--contrast1' } 
                />
                <WhySaatva />
                <ReviewSection reviews={props.reviewList} />
                <VideoSection />
                <CategoryTiles />
            </SelectionProvider>
        </Layout>
    )
}
